export const getters = {
  getUIFlags($state) {
    return $state.uiFlags;
  },

  getHandover($state) {
    return $state.record;
  },

  getConversationStatus($state) {
    return $state.conversationStatus;
  },

  getAINests($state) {
    return $state.aiNests;
  },

  getAISuggestions($state) {
    return $state.aiSuggestions;
  },

  getAIImprovement($state) {
    return $state.aiImprovement;
  },

  getConversationsStatuesState($state) {
    return $state.conversationsStatuesState;
  },

  getConversationStatusState($state) {
    return (id) => {
      return $state.conversationsStatuesState[id] || {};
    };
  },
};
