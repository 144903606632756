var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"input-translator flex flex-col flex-nowrap",class:{
    'w-max': !_vm.isExtended,
    'w-full': _vm.isExtended,
  }},[(!_vm.isExtended)?_c('div',{staticClass:"input-translator__toggler__container h-full w-max"},[_c('woot-button',{class:{
        'active rounded-b-none': _vm.isContainerVisible,
      },attrs:{"size":"small","color-scheme":"secondary","variant":"smooth","icon":"translate","type":"button"},on:{"click":_vm.toggleIsOpen}})],1):_vm._e(),_vm._v(" "),_c('slide-animation',{class:{
      'absolute right-0 top-full w-full': !_vm.isExtended, //replace bottom with top
    },attrs:{"duration":200,"is-shown":_vm.isContainerVisible}},[(_vm.isContainerVisible)?_c('div',{staticClass:"input-translator__container surface-highlight brd relative z-20 flex w-full flex-col gap-1 rounded-md border border-dashed p-2",class:{
        hidden: !_vm.isContainerVisible,
        '': _vm.isExtended,
        'rounded-tr-none': !_vm.isExtended,
      }},[_c('div',{staticClass:"input-translator__container__header flex flex-row flex-nowrap items-center gap-0.5 text-xxs leading-none"},[_c('span',{staticClass:"txt-muted"},[_vm._v("\n          "+_vm._s(_vm.label || _vm.$t('CHATLYN_TRANSLATOR.AUTO_TRANSLATE'))+"\n        ")]),_vm._v(" "),_c('language-select',{attrs:{"label":_vm.selectedDeeplLanguage.name || '',"languages":_vm.deeplLanguagesOptions},on:{"button-action":_vm.handleTranslateText},model:{value:(_vm.selectedLanguage),callback:function ($$v) {_vm.selectedLanguage=$$v},expression:"selectedLanguage"}})],1),_vm._v(" "),_c('div',{staticClass:"input-translator__container__body mt-0.5 flex flex-row flex-nowrap gap-2 py-2.5"},[_c('input-counter',{staticClass:"w-full",attrs:{"is-not-input":"","input-class":"input-translator__container__body__translated-text","value":_vm.translatedText || _vm.value,"max-length":_vm.maxLength,"is-hidden":!_vm.maxLength}},[_c('div',{staticClass:"input-translator__container__body__translated-text flex h-full items-center border-none bg-transparent text-woot-800 outline-none dark:text-woot-50"},[(_vm.translatedText || _vm.value)?[_vm._v("\n              "+_vm._s(_vm.translatedText || _vm.value)+"\n            ")]:_c('span',{staticClass:"text-gray-500 dark:text-gray-400"},[_vm._v("\n              "+_vm._s(_vm.$t('CHATLYN_TRANSLATOR.ERROR.NO_TEXT_PROVIDED'))+"\n            ")])],2)]),_vm._v(" "),_c('woot-button',{staticClass:"mt-auto !h-auto !w-auto border-0",attrs:{"color-scheme":"secondary","variant":"clear","size":"x-large","icon":"arrow-enter-left","type":"button","is-disabled":_vm.isInsertButtonDisabled},on:{"click":_vm.handleInsertButtonClick}})],1),_vm._v(" "),_c('chatlyn-spinner',{attrs:{"is-visible":_vm.isLoading}})],1):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }