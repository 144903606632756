<template>
  <li v-show="isMenuItemVisible" class="mt-1 select-none">
    <div v-if="hasSubMenu" class="block w-full">
      <woot-button
        class="mt-1 !h-8 w-full px-2 py-0 text-sm font-semibold text-gray-700"
        type="button"
        color-scheme="secondary"
        variant="clear"
        @click="toggleIsSubMenuExpanded"
      >
        <div
          v-if="!showSearchField"
          class="flex w-full flex-nowrap items-center justify-between gap-1"
        >
          <fluent-icon
            class="opacity-70 transition-transform dark:opacity-50"
            icon="chevron-right"
            :size="12"
            :class="{
              'rotate-90': isSubMenuExpanded,
            }"
          />

          <span>{{ customTranslation }}</span>

          <span
            class="ml-auto flex flex-row flex-nowrap items-center gap-1"
            @click.prevent
          >
            <button
              v-if="menuItem.hasSearch"
              class="flex items-center p-1"
              @click="toggleShowSearchField"
            >
              <fluent-icon icon="search" :size="14" />
            </button>

            <span class="inline-flex">
              <popover-select
                v-if="hasAddMenu"
                v-model="selectedAddOption"
                placement="bottom-end"
                :options="menuItem.menuOptions.main"
              >
                <button class="flex items-center py-1">
                  <fluent-icon icon="add" :size="16" />
                </button>
              </popover-select>

              <router-link
                v-else-if="showItem(menuItem)"
                v-slot="{ href, navigate }"
                :to="menuItem.toState"
                custom
              >
                <span class="-mt-1 pl-1" @click.stop>
                  <a :href="href">
                    <button
                      v-tooltip="{
                        content: $t(`${menuItem.newLinkTag}`),
                        delay: { show: 500, hide: 100 },
                      }"
                      :data-testid="menuItem.dataTestid"
                      @click="(e) => newLinkClick(e, navigate)"
                    >
                      <fluent-icon icon="add" :size="16" />
                    </button>
                  </a>
                </span>
              </router-link>
            </span>
          </span>
        </div>

        <div
          v-else
          class="secondary-menu__header__search-field-container relative"
        >
          <fluent-icon
            v-if="!showSearchField"
            class="transition-transform"
            icon="chevron-right"
            :size="16"
            :class="{
              'rotate-90': isSubMenuExpanded,
            }"
          />

          <span
            class="absolute left-0 flex items-center justify-center text-gray-400"
            :style="{
              height: '1.4rem',
              width: '1.4rem',
            }"
            @click.stop
          >
            <fluent-icon
              class="absolute text-gray-400"
              icon="search"
              :size="14"
            />

            <chatlyn-spinner
              class="!h-full !w-full !bg-transparent"
              :is-visible="isLoading || menuItem.isLoading"
            />
          </span>

          <span @click.stop>
            <woot-input
              :ref="queryInputRef"
              v-model="query"
              class="secondary-menu__header__search-field-input"
              type="text"
              :placeholder="menuItem.searchInputPlaceholder"
              autofocus
            />
          </span>

          <button
            v-if="menuItem.hasSearch"
            class="absolute right-0 -mt-0.5"
            @click.stop="toggleShowSearchField"
          >
            <fluent-icon icon="dismiss" :size="16" />
          </button>
        </div>
      </woot-button>

      <div v-if="menuItem.showNewButton" class="flex items-center">
        <woot-button
          size="tiny"
          variant="clear"
          color-scheme="secondary"
          icon="add"
          class="ml-2 p-0"
          @click="onClickOpen"
        />
      </div>
    </div>

    <router-link
      v-else
      class="m-0 flex items-center rounded p-2 text-sm leading-4 text-gray-700 transition-colors duration-200 hover:bg-gray-100 dark:text-gray-100 dark:hover:bg-gray-800"
      :class="computedClass"
      :to="menuItem && menuItem.toState"
    >
      <fluent-icon
        :icon="menuItem.icon"
        class="mr-1.5 min-w-[1rem] rtl:ml-1.5 rtl:mr-0"
        size="14"
      />

      <span v-dompurify-html="$t(`${menuItem.label}`)" />

      <span
        v-if="showChildCount(menuItem.count)"
        class="mx-1 rounded-md px-1 py-0 text-xxs font-medium"
        :class="{
          'text-gray-400 dark:text-gray-500': isCountZero && !isActiveView,
          'text-gray-600 dark:text-gray-100': !isCountZero && !isActiveView,
          'bg-woot-100 text-woot-600 dark:bg-woot-200 dark:text-woot-600':
            isActiveView,
          'bg-gray-100 dark:bg-gray-700': !isActiveView,
        }"
      >
        {{ `${menuItem.count}` }}
      </span>

      <span
        v-if="menuItem.beta"
        data-view-component="true"
        label="Beta"
        class="mx-1 inline-block rounded-full border border-green-500 px-1 text-xxs font-semibold leading-4 text-green-600"
      >
        {{ $t('SIDEBAR.BETA') }}
      </span>
    </router-link>

    <slide-animation
      v-if="hasSubMenu"
      :is-shown="isSubMenuExpanded || showSearchField"
    >
      <div
        class="pl-2"
        :class="{
          'pointer-events-none cursor-wait bg-gray-100 dark:bg-gray-800':
            isLoading || menuItem.isLoading,
        }"
      >
        <template v-if="menuItem.hasFolders">
          <drag-drop-list
            v-model="itemsComputed"
            class="secondary-menu__drag-drop-list mt-1"
            :menu-options="menuItem.menuOptions"
            :drag-disabled="!!query"
            :is-opened="showSearchField"
          >
            <template #listItem="{ listItem, listItemMenuOptions }">
              <secondary-child-nav-item
                :key="listItem.routeParams.id"
                :icon="
                  listItem.static
                    ? 'text-bullet-list-ltr'
                    : 'text-dynamic-list-ltr'
                "
                :item="listItem"
                :to="listItem.routeParams.toState"
                :label="listItem.routeParams.label"
                :label-color="listItem.routeParams.color"
                :should-truncate="listItem.routeParams.truncateLabel"
                :big-icon="listItem.routeParams.icon"
                :warning-icon="computedInboxErrorClass(listItem.routeParams)"
                :show-child-count="showChildCount(listItem.routeParams.count)"
                :child-item-count="listItem.routeParams.count"
                :menu-options="listItemMenuOptions"
              />
            </template>
          </drag-drop-list>

          <folders-list
            has-main-folder
            :label="this.$t('FOLDERS.ARCHIVED')"
            :list="archivedItems"
            :menu-options="menuItem.menuOptions.archived"
            :is-opened="showSearchField"
          >
            <template #listItem="{ listItem, listItemMenuOptions }">
              <secondary-child-nav-item
                :key="listItem.routeParams.id"
                :icon="
                  listItem.static
                    ? 'text-bullet-list-ltr'
                    : 'text-dynamic-list-ltr'
                "
                :item="listItem"
                :to="listItem.routeParams.toState"
                :label="listItem.routeParams.label"
                :label-color="listItem.routeParams.color"
                :should-truncate="listItem.routeParams.truncateLabel"
                :big-icon="listItem.routeParams.icon"
                :warning-icon="computedInboxErrorClass(listItem.routeParams)"
                :show-child-count="showChildCount(listItem.routeParams.count)"
                :child-item-count="listItem.routeParams.count"
                :menu-options="listItemMenuOptions"
              />
            </template>
          </folders-list>
        </template>

        <ul v-else class="mb-0 ml-0 grid list-none">
          <secondary-child-nav-item
            v-for="child in menuItem.children"
            :key="child.id"
            :to="child.toState"
            :label="child.label"
            :label-color="child.color"
            :should-truncate="child.truncateLabel"
            :big-icon="child.icon"
            :icon="computedInboxClass(child)"
            :warning-icon="computedInboxErrorClass(child)"
            :show-child-count="showChildCount(child.count)"
            :child-item-count="child.count"
          />

          <div
            v-if="!Boolean(menuItem.children.length)"
            class="py-1 text-center text-sm text-gray-400 dark:text-gray-600"
          >
            {{ menuItem.emptyStateText }}
          </div>
        </ul>
      </div>
    </slide-animation>
  </li>
</template>

<script>
import { mapGetters } from 'vuex';

import adminMixin from '../../../mixins/isAdmin';
import configMixin from 'shared/mixins/configMixin';
import {
  getInboxClassByType,
  getInboxWarningIconClass,
} from 'dashboard/helper/inbox';
import {
  isOnMentionsView,
  isOnUnattendedView,
} from '../../../store/modules/conversations/helpers/actionHelpers';
import getUuid from 'widget/helpers/uuid';
import {
  mapListStructureToFoldersAndItems,
  updateItemsAndFoldersKeys,
} from '../../ui/DragDropList/constants';

import WootButton from '../../ui/WootButton.vue';
import DragDropList from '../../ui/DragDropList/DragDropList.vue';
import SecondaryChildNavItem from './SecondaryChildNavItem.vue';
import PopoverSelect from '../../../../shared/components/ui/PopoverSelect.vue';
import ChatlynSpinner from '../../../../shared/components/ChatlynSpinner.vue';
import FoldersList from '../../ui/FoldersList/FoldersList.vue';
import SlideAnimation from '../../animations/SlideAnimation.vue';
import Policy from '../../policy.vue';

let queryTimeout;

export default {
  components: {
    SecondaryChildNavItem,
    WootButton,
    DragDropList,
    PopoverSelect,
    ChatlynSpinner,
    FoldersList,
    SlideAnimation,
    Policy,
  },
  mixins: [adminMixin, configMixin],
  props: {
    menuItem: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      query: '',
      showSearchField: false,
      isSubMenuExpanded: false,
      queryInputRef: `query-input-${getUuid()}`,
    };
  },
  computed: {
    ...mapGetters({
      activeInbox: 'getSelectedInbox',
      accountId: 'getCurrentAccountId',
      isFeatureEnabledonAccount: 'accounts/isFeatureEnabledonAccount',
      globalConfig: 'globalConfig/get',
      contactListsUiFlags: 'contactLists/getUiFlags',
      foldersState: 'folders/getFoldersState',
    }),

    isCountZero() {
      return this.menuItem.count === 0;
    },

    isActiveView() {
      return this.computedClass.includes('active-view');
    },

    customTranslation() {
      return this.menuItem.key === 'list'
        ? this.$t(this.menuItem.label)
        : this.$t(`${this.menuItem.label}`);
    },

    hasSubMenu() {
      if (this.menuItem.hasFolders) {
        return !!this.menuItem.children.active;
      }

      return !!this.menuItem.children;
    },

    isMenuItemVisible() {
      if (this.menuItem.globalConfigFlag) {
        return !!this.globalConfig[this.menuItem.globalConfigFlag];
      }
      if (this.menuItem.featureFlag) {
        return this.isFeatureEnabledonAccount(
          this.accountId,
          this.menuItem.featureFlag
        );
      }
      return true;
    },
    isAllConversations() {
      return (
        this.$store.state.route.name === 'inbox_conversation' &&
        this.menuItem.toStateName === 'home'
      );
    },
    isMentions() {
      return (
        isOnMentionsView({ route: this.$route }) &&
        this.menuItem.toStateName === 'conversation_mentions'
      );
    },
    isUnattended() {
      return (
        isOnUnattendedView({ route: this.$route }) &&
        this.menuItem.toStateName === 'conversation_unattended'
      );
    },
    isTeamsSettings() {
      return (
        this.$store.state.route.name === 'settings_teams_edit' &&
        this.menuItem.toStateName === 'settings_teams_list'
      );
    },
    isInboxSettings() {
      return (
        this.$store.state.route.name === 'settings_inbox_show' &&
        this.menuItem.toStateName === 'settings_inbox_list'
      );
    },
    isIntegrationsSettings() {
      return (
        this.$store.state.route.name === 'settings_integrations_webhook' &&
        this.menuItem.toStateName === 'settings_integrations'
      );
    },
    isApplicationsSettings() {
      return (
        this.$store.state.route.name === 'settings_applications_integration' &&
        this.menuItem.toStateName === 'settings_applications'
      );
    },
    isCurrentRoute() {
      return this.$store.state.route.name.includes(this.menuItem.toStateName);
    },

    computedClass() {
      // If active inbox is present, do not highlight conversations
      if (this.activeInbox) return ' ';
      if (
        this.isAllConversations ||
        this.isMentions ||
        this.isUnattended ||
        this.isCurrentRoute
      ) {
        return 'bg-woot-50 hover:bg-woot-50 dark:bg-woot-800 hover:dark:bg-woot-800 text-woot-600 dark:text-woot-400 hover:text-woot-600 dark:hover:text-woot-400 active-view';
      }
      if (this.hasSubMenu) {
        if (
          this.isTeamsSettings ||
          this.isInboxSettings ||
          this.isIntegrationsSettings ||
          this.isApplicationsSettings
        ) {
          return 'bg-woot-25 dark:bg-gray-800 text-woot-600 dark:text-woot-600 hover:text-woot-600 dark:hover:text-woot-600 active-view';
        }
        return 'hover:text-gray-700 dark:hover:text-gray-100';
      }

      return 'hover:text-gray-700 dark:hover:text-gray-100';
    },

    isLoading() {
      return Boolean(this.contactListsUiFlags.isLoading);
    },

    itemsComputed: {
      get() {
        return this.hasSearch
          ? this.menuItem.children.active.filter((child) => {
              if (Array.isArray(child.children)) {
                return !!child.children.length;
              }

              return true;
            })
          : this.menuItem.children.active;
      },

      async set(newValue) {
        const result = mapListStructureToFoldersAndItems(
          updateItemsAndFoldersKeys(newValue)
        );

        const archivedResult = mapListStructureToFoldersAndItems(
          updateItemsAndFoldersKeys(this.menuItem.children.archived)
        );

        await this.menuItem.updateFolders([
          ...result.folders,
          ...archivedResult.folders,
        ]);

        await this.menuItem.updateItems([
          ...result.items,
          ...archivedResult.items,
        ]);
      },
    },

    hasSearch() {
      return !!this.query;
    },

    archivedItems() {
      return this.hasSearch
        ? this.menuItem.children.archived.filter((child) => {
            if (Array.isArray(child.children)) {
              return !!child.children.length;
            }

            return true;
          })
        : this.menuItem.children.archived;
    },

    hasAddMenu() {
      return (
        Array.isArray(this.menuItem?.menuOptions?.main) &&
        this.menuItem.menuOptions.main.length
      );
    },

    selectedAddOption: {
      get() {
        return '';
      },

      async set(newValue) {
        const matchedOption = this.menuItem.menuOptions.main.find(
          (item) => item.value === newValue
        );

        if (!matchedOption || typeof matchedOption?.action !== 'function') {
          return;
        }

        // eslint-disable-next-line no-underscore-dangle
        await matchedOption.action(this.menuItem._id || this.menuItem.id);
      },
    },
  },
  watch: {
    query(newValue) {
      clearTimeout(queryTimeout);
      queryTimeout = setTimeout(
        () => {
          this.$emit('query-changed', newValue);
        },
        newValue ? 500 : 0
      );
    },

    showSearchField: {
      handler(newValue) {
        if (!newValue) {
          return;
        }

        this.$nextTick(() => {
          const inputElement = this.$refs[this.queryInputRef]?.$el?.children[0];

          if (!inputElement) {
            return;
          }

          inputElement.focus();
        });
      },
    },

    // items: {
    //   handler(newValue) {
    //     console.log(newValue);
    //   },
    //   deep: true,
    // },
  },
  mounted() {
    this.setupIsSubMenuExpandedState();
  },
  beforeDestroy() {
    clearTimeout(queryTimeout);
  },
  methods: {
    toggleShowSearchField() {
      this.showSearchField = !this.showSearchField;

      if (!this.showSearchField) {
        this.query = '';
      }
    },

    computedInboxClass(child) {
      const { type, phoneNumber } = child;
      if (!type) return '';
      const classByType = getInboxClassByType(
        type,
        phoneNumber,
        child.apiInboxType
      );
      return classByType;
    },
    computedInboxErrorClass(child) {
      const { type, reauthorizationRequired } = child;
      if (!type) return '';
      const warningClass = getInboxWarningIconClass(
        type,
        reauthorizationRequired
      );
      return warningClass;
    },
    newLinkClick(e, navigate) {
      if (this.menuItem.newLinkRouteName) {
        navigate(e);
      } else if (this.menuItem.showModalForNewItem) {
        if (this.menuItem.modalName === 'AddLabel') {
          e.preventDefault();
          this.$emit('add-label');
        } else if (this.menuItem.modalName === 'AddList') {
          e.preventDefault();
          this.$emit('add-list');
        } else if (this.menuItem.modalName === 'AddEntrypoint') {
          e.preventDefault();
          this.$emit('add-entrypoint');
        }
      }
    },
    showItem(item) {
      return this.isAdmin && !!item.newLink;
    },
    onClickOpen() {
      this.$emit('open');
    },
    showChildCount(count) {
      return Number.isInteger(count);
    },

    toggleIsSubMenuExpanded() {
      this.isSubMenuExpanded = !this.isSubMenuExpanded;

      this.$store.dispatch('folders/setFoldersState', {
        state: {
          [this.menuItem.key]: this.isSubMenuExpanded,
        },
      });
    },

    setupIsSubMenuExpandedState() {
      const savedIsExpandedState = this.foldersState[this.menuItem.key];

      this.isSubMenuExpanded =
        typeof savedIsExpandedState === 'boolean' ? savedIsExpandedState : true;
    },
  },
};
</script>
<style lang="scss" scoped>
.secondary-menu__header__search-field-container {
  @apply relative flex w-full flex-row flex-nowrap items-center;
  .secondary-menu__header__search-field-input {
    ::v-deep input {
      @apply h-8 rounded-none border-none bg-transparent p-0 pl-6 pr-7 text-sm font-normal focus:bg-transparent;
    }
  }
}
</style>
