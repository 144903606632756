import {
  ICON_AI_ASSIST,
  ICON_AI_EXPAND,
  ICON_AI_GRAMMAR,
  ICON_AI_SHORTEN,
} from '../../../../routes/dashboard/commands/CommandBarIcons';

const AGENT_INSTRUCTION = 'You are a helpful support agent.';

const LANGUAGE_INSTRUCTION =
  'Ensure that the reply should be in user language.';

const IMPROVE_INSTRUCTIONS = {
  REPHRASE: 'Please rephrase the following response.',
  FIX_SPELLING_GRAMMAR:
    'Please fix the spelling and grammar of the following response.',
  EXPAND: 'Please expand the following response.',
  SHORTEN: 'Please shorten the following response.',
  MAKE_FRIENDLY: 'Please make the following response more friendly.',
  MAKE_FORMAL: 'Please make the following response more formal.',
  SIMPLIFY: 'Please simplify the following response.',
};

const IMPROVE_INSTRUCTION_ICON = {
  REPHRASE: ICON_AI_ASSIST,
  FIX_SPELLING_GRAMMAR: ICON_AI_GRAMMAR,
  EXPAND: ICON_AI_EXPAND,
  SHORTEN: ICON_AI_SHORTEN,
  MAKE_FRIENDLY: ICON_AI_ASSIST,
  MAKE_FORMAL: ICON_AI_ASSIST,
  SIMPLIFY: ICON_AI_ASSIST,
};

const SUGGEST_INSTRUCTIONS = {
  SUGGEST: 'suggest',
};

const SUGGEST_INSTRUCTIONS_ICON = {
  SUGGEST: ICON_AI_ASSIST,
};

export {
  AGENT_INSTRUCTION,
  LANGUAGE_INSTRUCTION,
  IMPROVE_INSTRUCTIONS,
  IMPROVE_INSTRUCTION_ICON,
  SUGGEST_INSTRUCTIONS,
  SUGGEST_INSTRUCTIONS_ICON,
};
