import { INBOX_TYPES } from 'shared/mixins/inboxMixin';
import { format } from 'date-fns-tz';

const CH_I18N_TO_META_LANGUAGES_KEYS = {
  pt: 'pt_PT',
};

const MESSAGE_TEMPLATES_LANGUAGES_LABELS = {
  'pt-PT': 'pt',
  pt_PT: 'pt',
};

const DEFAULT_WHATSAPP_MESSAGE_TEMPLATE = {
  title: '',
  category: 'MARKETING',
  translations: [],
  phoneNumber: '15815301337',
};

const DEFAULT_SMS_MESSAGE_TEMPLATE = {
  title: '',
  translations: [],
};

const MESSAGE_TEMPLATES_STATUSES_OPTION = {
  PENDING: {
    title: 'CHATLYN_MESSAGE_TEMPLATE.LIST.STATUSES.PENDING_APPROVAL',
    color: 'var(--yellow-500)',
  },
  APPROVED: {
    title: 'CHATLYN_MESSAGE_TEMPLATE.LIST.STATUSES.APPROVED',
    color: 'var(--green-400)',
  },
  DRAFT: {
    title: 'CHATLYN_MESSAGE_TEMPLATE.LIST.STATUSES.DRAFT',
    color: 'var(--gray-300)',
  },
  REJECTED: {
    title: 'CHATLYN_MESSAGE_TEMPLATE.LIST.STATUSES.REJECTED',
    color: 'var(--red-500)',
  },
  SUBMITTED: {
    title: 'CHATLYN_MESSAGE_TEMPLATE.LIST.STATUSES.SUBMITTED',
    color: 'var(--white)',
  },
};

const MESSAGE_TEMPLATES_CHANNEL_TYPE = {
  UNIVERSAL: 'universal',
  SMS: 'sms',
  WHATSAPP: 'whatsapp',
  TELEGRAM: 'telegram',
};

const MESSAGE_TEMPLATES_CHANNEL_TYPE_DEFAULT = {
  [MESSAGE_TEMPLATES_CHANNEL_TYPE.SMS]: DEFAULT_SMS_MESSAGE_TEMPLATE,
  [MESSAGE_TEMPLATES_CHANNEL_TYPE.WHATSAPP]: DEFAULT_WHATSAPP_MESSAGE_TEMPLATE,
};

const MESSAGE_TEMPLATES_CHANNEL_TYPE_BY_INBOX_TYPE = {
  [INBOX_TYPES.SMS]: MESSAGE_TEMPLATES_CHANNEL_TYPE.SMS,
  [INBOX_TYPES.WHATSAPP]: MESSAGE_TEMPLATES_CHANNEL_TYPE.WHATSAPP,
  [INBOX_TYPES.TELEGRAM]: MESSAGE_TEMPLATES_CHANNEL_TYPE.TELEGRAM,
};

const MESSAGE_TEMPLATES_CHANNEL_TYPES_MENU_ITEM = {
  [MESSAGE_TEMPLATES_CHANNEL_TYPE.UNIVERSAL]: {
    id: 'channel-type-universal',
    title: 'CHATLYN_MESSAGE_TEMPLATE.CHANNEL_TYPES.UNIVERSAL',
    icon: 'text-box',
  },
  [MESSAGE_TEMPLATES_CHANNEL_TYPE.SMS]: {
    id: 'channel-type-sms',
    title: 'CHATLYN_MESSAGE_TEMPLATE.CHANNEL_TYPES.SMS',
    icon: 'brand-sms',
  },
  [MESSAGE_TEMPLATES_CHANNEL_TYPE.WHATSAPP]: {
    id: 'channel-type-whatsapp',
    title: 'CHATLYN_MESSAGE_TEMPLATE.CHANNEL_TYPES.WHATSAPP',
    icon: 'brand-whatsapp',
  },
  [MESSAGE_TEMPLATES_CHANNEL_TYPE.TELEGRAM]: {
    id: 'channel-type-telegram',
    title: 'CHATLYN_MESSAGE_TEMPLATE.CHANNEL_TYPES.TELEGRAM',
    icon: 'brand-telegram',
  },
};

const MESSAGE_TEMPLATES_PREVIEW_CLASS = {
  [MESSAGE_TEMPLATES_CHANNEL_TYPE.UNIVERSAL]: 'message-preview--universal',
  [MESSAGE_TEMPLATES_CHANNEL_TYPE.SMS]: 'message-preview--sms',
  [MESSAGE_TEMPLATES_CHANNEL_TYPE.WHATSAPP]: 'message-preview--whatsapp',
  [MESSAGE_TEMPLATES_CHANNEL_TYPE.TELEGRAM]: 'message-preview--telegram',
};

const CREATE_MESSAGE_TEMPLATE_STEP = {
  TYPE: 'type',
  MAIN: 'main',
  LANGUAGES: 'languages',
};

const MESSAGE_TEMPLATE_AVAILABLE_TRANSLATION_LANGUAGE = [
  'en',
  'de',
  'fr',
  'it',
  'es',
  'ca',
  'ar',
  'nl',
  'hu',
  'pl',
  'pt',
  'ro',
  'ru',
  // 'sr',
  'sk',
  'sl',
  'he',
];

const getMessageTemplateStepHeader = () => {
  const result = {};

  Object.values(CREATE_MESSAGE_TEMPLATE_STEP).forEach((step) => {
    result[step] = {};

    Object.values(MESSAGE_TEMPLATES_CHANNEL_TYPE).forEach((type) => {
      const mainPath = `CHATLYN_MESSAGE_TEMPLATE.CREATE_MODAL.TITLE.${step.toUpperCase()}.${type.toUpperCase()}`;

      result[step][type] = {
        title: `${mainPath}.TEXT`,
        tooltip: `${mainPath}.TOOLTIP`,
      };
    });
  });

  return result;
};

const CREATE_MESSAGE_TEMPLATE_STEP_HEADER = getMessageTemplateStepHeader();

const DATE_FORMAT = ['DD/MM/YYYY', 'D.M.YYYY', 'D.M.'];
const TIME_FORMAT = ['HH:mm', 'h:mm a'];

const DATE_FORMAT_FNS_FORMAT = {
  'DD/MM/YYYY': 'dd/MM/yyyy',
  'D.M.YYYY': 'd.M.yyyy',
  'D.M.': 'd.M.',
};

const GET_DATE_FORMAT_EXAMPLE = (date) => {
  const example = {};

  DATE_FORMAT.forEach((dateFormat) => {
    example[dateFormat] = format(date, DATE_FORMAT_FNS_FORMAT[dateFormat]);
  });

  return example;
};

const GET_TIME_FORMAT_EXAMPLE = (date) => {
  const example = {};

  TIME_FORMAT.forEach((timeFormat) => {
    example[timeFormat] = format(date, timeFormat);
  });

  return example;
};

export {
  CH_I18N_TO_META_LANGUAGES_KEYS,
  MESSAGE_TEMPLATES_LANGUAGES_LABELS,
  MESSAGE_TEMPLATES_STATUSES_OPTION,
  MESSAGE_TEMPLATES_CHANNEL_TYPE,
  MESSAGE_TEMPLATES_CHANNEL_TYPE_DEFAULT,
  MESSAGE_TEMPLATES_CHANNEL_TYPE_BY_INBOX_TYPE,
  MESSAGE_TEMPLATES_CHANNEL_TYPES_MENU_ITEM,
  MESSAGE_TEMPLATES_PREVIEW_CLASS,
  CREATE_MESSAGE_TEMPLATE_STEP,
  CREATE_MESSAGE_TEMPLATE_STEP_HEADER,
  MESSAGE_TEMPLATE_AVAILABLE_TRANSLATION_LANGUAGE,
  DATE_FORMAT,
  TIME_FORMAT,
  GET_DATE_FORMAT_EXAMPLE,
  GET_TIME_FORMAT_EXAMPLE,
};
