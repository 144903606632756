var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex flex-col gap-2"},[(_vm.isFormulateFormComponent)?_c('FormulateInput',{ref:"textarea",staticClass:"formulate-resizable-text-area",style:({
      height: 'auto',
      '--resizeTextAreaMinHeight': `${_vm.minHeight}rem`,
    }),attrs:{"id":_vm.id,"type":"textarea","placeholder":_vm.placeholder,"validation":_vm.validation || undefined,"validation-name":_vm.validationName || undefined,"validation-messages":_vm.validationMessages,"validation-rules":_vm.validationRules,"error-behavior":_vm.errorBehavior || undefined,"help":_vm.help || undefined,"maxlength":_vm.maxLength > 0 ? _vm.maxLength : undefined,"disabled":_vm.isDisabled},on:{"focus":_vm.onFocus,"keyup":_vm.onKeyup,"blur":_vm.onBlur},model:{value:(_vm.formulateValue),callback:function ($$v) {_vm.formulateValue=$$v},expression:"formulateValue"}}):_c('textarea',{ref:"textarea",staticClass:"transparent borderless resize-none py-2",style:({
      minHeight: `${_vm.minHeight}rem`,
      maxHeight: `${_vm.minHeight * 2}rem`,
    }),attrs:{"id":_vm.id,"placeholder":_vm.placeholder,"rows":_vm.rows,"maxlength":_vm.maxLength > 0 ? _vm.maxLength : undefined,"disabled":_vm.isDisabled},domProps:{"value":_vm.value},on:{"input":_vm.onInput,"focus":_vm.onFocus,"keyup":_vm.onKeyup,"blur":_vm.onBlur}}),_vm._v(" "),(_vm.hasFooter)?_c('div',{staticClass:"flex flex-col gap-2"},[_vm._t("footer")],2):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }