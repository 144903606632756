const getFixedVerticalPosition = (element) => {
  if (!element) {
    return {};
  }

  const subMenuBoundingRect = element.getBoundingClientRect();

  const menuBottomPosition = subMenuBoundingRect.height + subMenuBoundingRect.y;

  if (menuBottomPosition <= window.innerHeight) {
    return {};
  }

  const yDifference = menuBottomPosition - window.innerHeight;

  const newYPosition =
    subMenuBoundingRect.y - (menuBottomPosition - window.innerHeight);

  return {
    newY: newYPosition < 0 ? 0 : newYPosition,
    yDifference: yDifference,
    biggerThanScreen: window.innerHeight < subMenuBoundingRect.height,
  };
};

export { getFixedVerticalPosition };
