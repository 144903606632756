export const LOCAL_STORAGE_KEYS = {
  DISMISSED_UPDATES: 'dismissedUpdates',
  WIDGET_BUILDER: 'widgetBubble_',
  DRAFT_MESSAGES: 'draftMessages',
  JWT_AUTH_TOKENS: 'jwtAuthTokens',
  COLOR_SCHEME: 'color_scheme',
  DISMISSED_LABEL_SUGGESTIONS: 'labelSuggestionsDismissed',
  MESSAGE_REPLY_TO: 'messageReplyTo',
  CHATLYN_AI_KEY: 'chatlynAIKey',
};
