// import Vue from 'vue';
import types from '../../mutation-types';

export const mutations = {
  [types.SET_CHATBOT_UI_FLAGS]($state, payload) {
    $state.uiFlags = { ...$state.uiFlags, ...payload };
  },

  [types.SET_CHATBOT_HANDOVER]($state, payload) {
    $state.record = payload;
  },
  [types.CLEAR_CHATBOT_HANDOVER]($state) {
    $state.record = null;
  },

  [types.SET_CHATBOT_CONVERSATION_STATUS]($state, payload) {
    $state.conversationStatus = payload;
  },
  [types.CLEAT_CHATBOT_CONVERSATION_STATUS]($state) {
    $state.conversationStatus = null;
  },

  [types.SET_CHATBOT_AI_NESTS]($state, payload) {
    $state.aiNests = payload;
  },
  [types.CLEAR_CHATBOT_AI_NESTS]($state) {
    $state.aiNests = [];
  },

  [types.SET_CHATBOT_AI_SUGGESTIONS]($state, payload) {
    $state.aiSuggestions = payload;
  },
  [types.CLEAR_CHATBOT_AI_SUGGESTIONS]($state) {
    $state.aiSuggestions = [];
  },

  [types.SET_CHATBOT_AI_IMPROVEMENT]($state, payload) {
    $state.aiImprovement = payload;
  },
  [types.CLEAR_CHATBOT_AI_IMPROVEMENT]($state) {
    $state.aiImprovement = null;
  },

  [types.SET_CHATBOT_CONVERSATIONS_STATUSES_STATE]($state, payload) {
    $state.conversationsStatuesState = {
      ...$state.conversationsStatuesState,
      ...payload,
    };
  },
};
