export const getters = {
  getUserLanguage: ($state) => {
    return $state.userLanguage;
  },

  getUserProfileDetails: ($state) => {
    return $state.userProfileDetails;
  },

  getAccount: ($state) => {
    return $state.account;
  },

  getChatBubbles: ($state) => {
    return $state.chatBubbles;
  },

  getSignatures: ($state) => {
    return $state.signatures;
  },

  getInboxOptOut: ($state) => {
    return $state.inboxOptOut;
  },

  getSms: ($state) => {
    return $state.sms;
  },

  getInboxOptOuts: ($state) => {
    return $state.inboxOptOuts;
  },

  getUiFlags: ($state) => {
    return $state.uiFlags;
  },

  getIsLoading: ($state) => {
    return Boolean(
      $state.isCreating ||
        $state.isFetching ||
        $state.isUpdating ||
        $state.isMerging ||
        $state.isDeleting
    );
  },
};
