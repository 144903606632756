var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"editorRoot",staticClass:"editor-root relative"},[(_vm.showUserMentions && _vm.isPrivate)?_c('tag-agents',{attrs:{"search-key":_vm.mentionSearchKey},on:{"click":_vm.insertMentionNode}}):_vm._e(),_vm._v(" "),(_vm.shouldShowCannedResponses)?_c('canned-response',{attrs:{"search-key":_vm.cannedSearchTerm},on:{"click":_vm.insertCannedResponse}}):_vm._e(),_vm._v(" "),(_vm.shouldShowVariables)?_c('variable-list',{attrs:{"search-key":_vm.variableSearchTerm},on:{"click":_vm.insertVariable}}):_vm._e(),_vm._v(" "),_c('input',{ref:"imageUpload",attrs:{"type":"file","accept":"image/png, image/jpeg, image/jpg, image/gif, image/webp","hidden":""},on:{"change":_vm.onFileChange}}),_vm._v(" "),_c('div',{ref:"editor",style:({
      '--textAreaMinHeight': `${_vm.minHeight}rem`,
      '--textAreaMaxHeight': `${_vm.minHeight * 2}rem`,
    })}),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isImageNodeSelected && _vm.showImageResizeToolbar),expression:"isImageNodeSelected && showImageResizeToolbar"}],staticClass:"absolute flex gap-1 rounded bg-gray-50 px-1 py-1 text-gray-800 shadow-md dark:bg-gray-700 dark:text-gray-50",style:({
      top: _vm.toolbarPosition.top,
      left: _vm.toolbarPosition.left,
    })},_vm._l((_vm.sizes),function(size){return _c('button',{key:size.name,staticClass:"rounded border border-solid border-gray-200 px-1.5 py-0.5 text-xs font-medium hover:bg-gray-100 dark:border-gray-600 dark:hover:bg-gray-800",on:{"click":function($event){return _vm.setURLWithQueryAndImageSize(size)}}},[_vm._v("\n      "+_vm._s(size.name)+"\n    ")])}),0),_vm._v(" "),_vm._t("footer")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }