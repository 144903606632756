<template>
  <div
    v-if="isAiSuggestionButtonVisible"
    class="ai-suggestion-button relative flex h-8 select-none flex-row flex-nowrap items-stretch gap-0.5 rounded bg-violet-400 p-[3px] dark:bg-violet-500"
  >
    <template v-if="!message">
      <popover-select
        v-if="hasManyNests"
        v-model="selectedAiNestId"
        class="ai-suggestion-button__nest"
        :options="aiNestsOptions"
        placement="top-end"
        :disabled="isAISuggestionLoading"
        @change-is-popover-select-open="changeIsNestsPopoverOpen"
      >
        <div
          class="flex h-full cursor-pointer items-center gap-0.5 rounded bg-violet-600 px-1.5 py-0.5 text-sm !text-white dark:bg-violet-700"
        >
          <span v-if="selectedNest" class="text-xs">
            {{ getInitial(selectedNest.title) || selectedNest.nestId }}
          </span>

          <span>
            <fluent-icon
              :size="14"
              icon="chevron-down"
              class="transition-transform"
              :class="{
                'rotate-180': isNestsPopoverOpen,
              }"
            />
          </span>
        </div>
      </popover-select>

      <woot-button
        class="ai-suggestion-button__woot-button !h-full !gap-1 !bg-transparent !px-1.5 !py-0.5 !text-xs !leading-none !text-white hover:!bg-violet-600 dark:hover:!bg-violet-700"
        :is-disabled="isSuggestButtonDisabled"
        size="small"
        variant="clear"
        type="button"
        @click="fetchAISuggestions"
      >
        <div class="flex h-full w-full flex-row items-center gap-1">
          <span v-if="!isAISuggestionLoading" class="-mt-0.5">
            <fluent-icon icon="wand" :size="16" />
          </span>

          <span v-else class="relative w-4 self-stretch">
            <chatlyn-spinner :is-visible="isAISuggestionLoading" />
          </span>

          <span> {{ $t('CONVERSATION.AI_SUGGESTION.SUGGEST') }} </span>
        </div>
      </woot-button>
    </template>

    <woot-button
      v-else-if="aiApiKey"
      class="relative !h-full !gap-1 !bg-transparent !px-1.5 !py-0.5 !text-xs !leading-none !text-white hover:!bg-violet-600 dark:hover:!bg-violet-700"
      :is-disabled="isAIImprovementLoading"
      size="small"
      variant="clear"
      type="button"
      @click="handleOpenAIImproveOptionsModalClick"
    >
      <div class="flex h-full w-full flex-row items-center gap-1">
        <span v-if="!isAIImprovementLoading" class="-mt-0.5">
          <fluent-icon icon="wand" :size="16" />
        </span>

        <span v-else class="relative w-4 self-stretch">
          <chatlyn-spinner :is-visible="isAIImprovementLoading" />
        </span>

        <span>
          {{ $t('CONVERSATION.AI_SUGGESTION.IMPROVE') }}
        </span>
      </div>
    </woot-button>
  </div>
</template>

<script>
// Libs //
import { mapGetters } from 'vuex';

// API //
import ManagementAPI from '../../../../api/management';

// Components //
import ChatlynSpinner from '../../../../../shared/components/ChatlynSpinner.vue';
import PopoverSelect from '../../../../../shared/components/ui/PopoverSelect.vue';

// Constants //
import { MESSAGE_TYPE } from '../../../../../shared/constants/messages';
import {
  CMD_AI_IMPROVE,
  CMD_AI_SUGGEST,
} from '../../../../routes/dashboard/commands/commandBarBusEvents';
import {
  AGENT_INSTRUCTION,
  IMPROVE_INSTRUCTIONS,
  LANGUAGE_INSTRUCTION,
} from './constants';

export default {
  name: 'AiSuggestionButton',
  components: { PopoverSelect, ChatlynSpinner },

  props: {
    message: {
      type: String,
      default: '',
    },

    canReply: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      aiApiKey: '',
      isNestsPopoverOpen: false,
      selectedAiNestId: '',
    };
  },

  computed: {
    ...mapGetters({
      currentAccountId: 'getCurrentAccountId',
      currentChat: 'getSelectedChat',
      aiNests: 'chatbot/getAINests',
      aiSuggestions: 'chatbot/getAISuggestions',
      chatbotUIFlags: 'chatbot/getUIFlags',
    }),

    currentConversationStatusState() {
      return this.$store.getters['chatbot/getConversationStatusState'](
        this.currentChat?.id
      );
    },

    isAiSuggestionButtonVisible() {
      return !this.message || this.aiApiKey;
    },

    hasManyNests() {
      return this.aiNests?.length > 1;
    },

    aiNestsOptions() {
      return (
        this.aiNests
          .filter((aiNest) => !!aiNest.title)
          .map((aiNest) => ({
            label: aiNest.title,
            value: `${aiNest.id}`,
          })) || []
      );
    },

    selectedNest() {
      return this.aiNests?.find(
        (nest) => `${nest.id}` === this.selectedAiNestId
      );
    },

    isSuggestButtonDisabled() {
      return (
        !this.canReply ||
        !this.selectedNest ||
        !this.latestIncomingMessagesContent ||
        this.isAISuggestionLoading
      );
    },

    incomingMessages() {
      return (
        this.currentChat?.messages?.filter(
          (message) => message.message_type === MESSAGE_TYPE.INCOMING
        ) || []
      );
    },

    latestIncomingMessages() {
      return (
        this.incomingMessages?.slice(
          Math.max(this.incomingMessages.length - 3, 0)
        ) || []
      );
    },

    latestIncomingMessagesContent() {
      return (
        this.latestIncomingMessages
          ?.map((message) => message?.content?.trim())
          ?.join(' ') || ''
      );
    },

    isAISuggestionLoading() {
      return this.chatbotUIFlags.isAISuggestionLoading;
    },

    isAIImprovementLoading() {
      return this.chatbotUIFlags.isAIImprovementLoading;
    },
  },

  watch: {
    aiNestsOptions: {
      handler(newValue) {
        if (!newValue || !newValue?.length) {
          this.selectedAiNestId = '';
          return;
        }

        if (
          this.selectedAiNestId &&
          newValue.some((nest) => nest.value === `${this.selectedAiNestId}`)
        ) {
          return;
        }

        this.selectedAiNestId = newValue[0].value;
      },
      deep: true,
      immediate: true,
    },

    isSuggestButtonDisabled: {
      handler(newValue) {
        this.$nextTick(() => {
          this.setConversationsStatuesState({
            [this.currentChat.id]: {
              ...this.currentConversationStatusState,
              hasSuggestion: !newValue,
            },
          });
        });
      },
      immediate: true,
    },

    aiApiKey: {
      handler(newValue) {
        this.$nextTick(() => {
          this.setConversationsStatuesState({
            [this.currentChat.id]: {
              ...this.currentConversationStatusState,
              hasImprovement: !!newValue,
            },
          });
        });
      },
      immediate: true,
    },

    'currentChat.id': {
      handler(newValue, prevValue) {
        if (newValue === prevValue) {
          return;
        }

        this.$nextTick(() => {
          this.setConversationsStatuesState({
            [this.currentChat.id]: {
              ...this.currentConversationStatusState,
              hasSuggestion: !this.isSuggestButtonDisabled,
              hasImprovement: !!this.aiApiKey,
            },
          });
        });
      },
    },
  },

  mounted() {
    this.$emitter.on(CMD_AI_SUGGEST, this.onAISuggestOptionSelect);
    this.$emitter.on(CMD_AI_IMPROVE, this.onAIImproveOptionSelect);
    this.getAIApiKey();
  },

  methods: {
    setConversationsStatuesState(newValue) {
      this.$store.dispatch('chatbot/setConversationsStatuesState', {
        state: newValue,
      });
    },

    async getAIApiKey() {
      this.aiApiKey = await ManagementAPI.getAIApiKey(this.currentAccountId);
    },

    getInitial(name = '') {
      const parts = name.split(/[ -]/);
      let initials = parts.reduce((acc, curr) => acc + curr.charAt(0), '');

      if (initials.length > 2 && initials.search(/[A-Z]/) !== -1) {
        initials = initials.replace(/[a-z]+/g, '');
      }
      initials = initials.substring(0, 2).toUpperCase();

      return initials;
    },

    changeIsNestsPopoverOpen(newValue) {
      this.isNestsPopoverOpen = newValue;
    },

    async fetchAISuggestions() {
      if (
        !this.selectedNest ||
        !this.currentChat ||
        !this.latestIncomingMessagesContent
      ) {
        return;
      }

      this.$store.dispatch('chatbot/getAISuggestions', {
        nestId: this.selectedNest.nestId,
        accountId: this.currentAccountId,
        message: this.latestIncomingMessagesContent,
        guestIdentifier: `${this.currentAccountId}-${this.currentChat.id}`,
        // answerLength,
      });
    },

    handleOpenAIImproveOptionsModalClick() {
      const ninja = document.querySelector('ninja-keys');
      ninja.open({ parent: 'improve_options' });
    },

    async onAISuggestOptionSelect() {
      await this.fetchAISuggestions();
    },

    async onAIImproveOptionSelect(key) {
      if (!this.message?.trim()) {
        return;
      }

      const improveOption = IMPROVE_INSTRUCTIONS[key];

      const prompt =
        `${AGENT_INSTRUCTION} ${improveOption} ${LANGUAGE_INSTRUCTION}
Return me json with format: { "language": "en", "content": "{{improved_text}}" }
improved_text is a improved (shortened, fixed text that I will provide you), language is detected by you.
Text: ${this.message}`.trim();

      await this.$store.dispatch('chatbot/getAIImprovement', {
        apiToken: this.aiApiKey,
        prompt,
        maxTokens: 100,
        stream: false,
        temperature: 0.7,
        serviceProvider: 'OPEN_AI',
      });
    },
  },
};
</script>

<style lang="scss">
.ai-suggestion-button {
  .ai-suggestion-button__nest {
    .v-popover {
      @apply flex;
    }
  }

  .ai-suggestion-button__woot-button {
    .button__content {
      @apply h-full;
    }
  }

  .chatlyn-spinner {
    @apply bg-transparent;

    .chatlyn-spinner__circle {
      @apply h-3 w-3 border border-l-transparent;
    }
  }
}
</style>
