var render = function render(){var _vm=this,_c=_vm._self._c;return _c('formulate-form',{staticClass:"column content-box",scopedSlots:_vm._u([{key:"default",fn:function({ hasErrors }){return [_c('woot-modal-header',{attrs:{"header-title":_vm.isContacts
        ? _vm.$t('CHATLYN_CONTACT_LISTS.SEND_MESSAGE_MODAL.TITLE')
        : _vm.$t('CHATLYN_MESSAGE_TEMPLATE.SEND_MODAL.TITLE', {
            TEMPLATE_NAME: _vm.messageTemplate.title,
          })}}),_vm._v(" "),_c('div',{staticClass:"modal-content send-message-template-modal-body"},[(!_vm.isAllTranslationsApproved)?_c('div',{staticClass:"callout warning mb-3 flex items-center gap-2 p-3"},[_c('fluent-icon',{staticClass:"flex-shrink-0",attrs:{"icon":"warning","size":16}}),_vm._v(" "),_c('span',[_vm._v("\n        "+_vm._s(_vm.$t(
            'CHATLYN_MESSAGE_TEMPLATE.SEND_MODAL.NOT_ALL_TRANSLATIONS_APPROVED_MESSAGE'
          ))+"\n      ")])],1):_vm._e(),_vm._v(" "),(_vm.isShowWarning)?_c('div',{staticClass:"message-template-10-minutes-warning-message callout alert mb-3 flex items-start gap-2 p-3"},[_c('fluent-icon',{staticClass:"mt-1 flex-shrink-0",attrs:{"icon":"warning","size":16}}),_vm._v(" "),_c('div',{staticClass:"message-template-10-minutes-warning-message__content"},[_c('div',{staticClass:"text-base font-semibold"},[_vm._v("\n          "+_vm._s(_vm.$t('CHATLYN_MESSAGE_TEMPLATE.SEND_MODAL.10_MINUTES_WARNING.TITLE'))+"\n        ")]),_vm._v(" "),_c('div',{staticClass:"text-sm"},[_vm._v("\n          "+_vm._s(_vm.$t(
              'CHATLYN_MESSAGE_TEMPLATE.SEND_MODAL.10_MINUTES_WARNING.MESSAGE'
            ))+"\n        ")])])],1):_vm._e(),_vm._v(" "),_c('div',{staticClass:"grid gap-8 md:grid-cols-2 lg:grid-cols-5"},[_c('div',{staticClass:"col-span-1 flex flex-col gap-3 lg:col-span-3"},[(_vm.isContacts)?[_c('multiselect-ch',{attrs:{"title":_vm.$t('CHATLYN_CONTACT_LISTS.SEND_MESSAGE_MODAL.TEMPLATE'),"options":_vm.availableMessageTemplates,"multiple":false,"label":"title","max-height":140},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('span',{staticClass:"mr-1 flex items-center"},[_vm._v("\n                "+_vm._s(_vm.$t('CHATLYN_CONTACT_LISTS.SEND_MESSAGE_MODAL.TEMPLATE'))+"\n              ")]),_vm._v(" "),_c('fluent-icon',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:(
                  _vm.$t(
                    'CHATLYN_CONTACT_LISTS.SEND_MESSAGE_MODAL.TEMPLATE_TOOLTIP'
                  )
                ),expression:"\n                  $t(\n                    'CHATLYN_CONTACT_LISTS.SEND_MESSAGE_MODAL.TEMPLATE_TOOLTIP'\n                  )\n                ",modifiers:{"top":true}}],attrs:{"icon":"info","size":"16"}})]},proxy:true}],null,true),model:{value:(_vm.template),callback:function ($$v) {_vm.template=$$v},expression:"template"}})]:[_c('multiselect-ch',{attrs:{"title":_vm.$t('CHATLYN_MESSAGE_TEMPLATE.SEND_MODAL.SELECT_LIST'),"options":_vm.contactLists,"multiple":false,"label":"name","max-height":200},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('label',{staticClass:"mr-1 flex items-center"},[_vm._v("\n                "+_vm._s(_vm.$t('CHATLYN_MESSAGE_TEMPLATE.SEND_MODAL.SELECT_LIST'))+"\n              ")])]},proxy:true}],null,true),model:{value:(_vm.contactList),callback:function ($$v) {_vm.contactList=$$v},expression:"contactList"}})],_vm._v(" "),(!_vm.hasLinks)?_c('div',{staticClass:"message-template-scheduled-container",staticStyle:{"gap":"0","margintop":"0"}},[_c('div',{staticClass:"flex flex-row flex-nowrap items-center gap-2"},[_c('span',[_vm._v("\n              "+_vm._s(_vm.$t('CHATLYN_MESSAGE_TEMPLATE.SEND_MODAL.RECIPIENTS'))+"\n            ")]),_vm._v(" "),(_vm.contactList && _vm.contactList.name)?_c('fluent-icon',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:(
                _vm.$t(
                  'CHATLYN_CONTACT_LISTS.SEND_MESSAGE_MODAL.RECIPIENTS_TOOLTIP',
                  { list: _vm.contactList.name }
                )
              ),expression:"\n                $t(\n                  'CHATLYN_CONTACT_LISTS.SEND_MESSAGE_MODAL.RECIPIENTS_TOOLTIP',\n                  { list: contactList.name }\n                )\n              ",modifiers:{"top":true}}],attrs:{"icon":"info","size":"16"}}):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"text-4xl font-light dark:text-white"},[_vm._v("\n            "+_vm._s(_vm.contactsCount)+"\n          ")]),_vm._v(" "),(_vm.isSendMessageLimitShown)?_c('send-message-limit-warning',{staticClass:"mt-0.5",attrs:{"current-sending-limits":_vm.currentSendingLimits}}):_vm._e()],1):_vm._e(),_vm._v(" "),_c('div',{staticClass:"message-template-scheduled-container !gap-0"},[_c('div',{staticClass:"message-template-scheduled-switcher"},[_c('woot-switch',{attrs:{"id":"message-template-scheduled-switcher","label":_vm.$t('CHATLYN_CONTACT_LISTS.SEND_MESSAGE_MODAL.SCHEDULE')},model:{value:(_vm.showSchedule),callback:function ($$v) {_vm.showSchedule=$$v},expression:"showSchedule"}})],1),_vm._v(" "),_c('slide-animation',{attrs:{"is-shown":_vm.showSchedule}},[(_vm.showSchedule)?_c('div',{staticClass:"schedule mt-3 flex w-full flex-wrap gap-x-4 gap-y-2"},[_c('FormulateInput',{staticClass:"flex-1",attrs:{"type":"date","label":_vm.$t('CHATLYN_CONTACT_LISTS.SEND_MESSAGE_MODAL.DATE'),"placeholder":"$t('CHATLYN_CONTACT_LISTS.SEND_MESSAGE_MODAL.DATE')","min":_vm.minDate,"validation":_vm.minDate ? `required|after:${_vm.afterDate}` : 'required'},model:{value:(_vm.scheduleDate),callback:function ($$v) {_vm.scheduleDate=$$v},expression:"scheduleDate"}}),_vm._v(" "),_c('FormulateInput',{staticClass:"flex-1",attrs:{"type":"time","label":_vm.$t('CHATLYN_CONTACT_LISTS.SEND_MESSAGE_MODAL.TIME'),"placeholder":"$t('CHATLYN_CONTACT_LISTS.SEND_MESSAGE_MODAL.TIME')","validation":_vm.minTime ? `required|timeAfter:${_vm.minTime}` : 'required',"validation-messages":{
                  timeAfter: _vm.$t(
                    'CHATLYN_GENERAL.MESSAGES.AFTER_TIME_INVALID',
                    {
                      time: _vm.minTime,
                    }
                  ),
                },"validation-rules":{
                  timeAfter: (event) => _vm.timeAfter(event, _vm.minTime),
                },"min":_vm.minTime},model:{value:(_vm.scheduleTime),callback:function ($$v) {_vm.scheduleTime=$$v},expression:"scheduleTime"}}),_vm._v(" "),_c('div',{staticClass:"w-full text-xs txt-muted"},[_vm._v(_vm._s(_vm.timezoneTitle))])],1):_vm._e()])],1),_vm._v(" "),(_vm.template && _vm.template.translations && _vm.hasLinks)?[_c('div',{staticClass:"message-template-links-message callout warning"},[_c('span',{staticClass:"whitespace-nowrap p-0.5"},[_vm._v("{ }")]),_vm._v(" "),_c('span',[_vm._v("\n              "+_vm._s(_vm.$t('CHATLYN_MESSAGE_TEMPLATE.SEND_MODAL.LINKS_MESSAGE'))+"\n            ")])]),_vm._v(" "),(_vm.template && _vm.template.translations)?_c('div',{staticClass:"message-template-scheduled-container pt-3"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.template.translations.length > 1),expression:"template.translations.length > 1"}],staticClass:"rounded-lg p-3 transition-colors duration-200",class:{
                'bg-woot-50 dark:bg-woot-800': _vm.isTranslationsAttributesSynced,
                'bg-gray-50 dark:bg-gray-700':
                  !_vm.isTranslationsAttributesSynced,
              }},[_c('woot-switch',{staticClass:"message-template-link-sync-switch",attrs:{"id":`message-template-link-sync-${_vm.currentLanguage}-switch`,"label":_vm.$t(
                    'CHATLYN_MESSAGE_TEMPLATE.CREATE_MODAL.SYNC_TRANSLATION_ATTRIBUTES'
                  )},model:{value:(_vm.isTranslationsAttributesSynced),callback:function ($$v) {_vm.isTranslationsAttributesSynced=$$v},expression:"isTranslationsAttributesSynced"}})],1),_vm._v(" "),_c('language-bar',{attrs:{"has-color-mark":"","has-additional-text":false,"languages":_vm.languages,"translations":_vm.template.translations,"current-language":_vm.currentLanguage},on:{"change":_vm.selectCurrentLanguage}}),_vm._v(" "),_vm._l((_vm.template.translations),function(translation){return [(
                  translation.status === 'approved' &&
                  _vm.getTranslationLinks(translation).links.length &&
                  _vm.hasLinks
                )?_c('links-before-send-editor',{directives:[{name:"show",rawName:"v-show",value:(translation.language === _vm.currentLanguage),expression:"translation.language === currentLanguage"}],key:translation.language,attrs:{"new-links":_vm.newLinks,"language-links":_vm.getTranslationLinks(translation),"buttons-component":_vm.getTranslationButtonsComponents(translation),"is-translations-attributes-synced":_vm.isTranslationsAttributesSynced,"current-language":_vm.currentLanguage,"is-custom-media-required":false},on:{"change-new-links":_vm.changeNewLinks,"change-language-new-links":_vm.changeLanguageNewLinks}}):_c('div',{directives:[{name:"show",rawName:"v-show",value:(translation.language === _vm.currentLanguage),expression:"translation.language === currentLanguage"}],key:translation.language,staticClass:"message-template-links-message"},[_c('span',[_c('fluent-icon',{attrs:{"icon":"warning","size":16}})],1),_vm._v(" "),_c('span',[_vm._v("\n                  "+_vm._s(_vm.$t('CHATLYN_MESSAGE_TEMPLATE.SEND_MODAL.NO_LINKS'))+"\n                ")])])]})],2):_vm._e()]:_vm._e()],2),_vm._v(" "),_c('div',{staticClass:"col-span-1 flex flex-col gap-3 lg:col-span-2"},[(_vm.hasLinks)?_c('div',[_c('div',{staticClass:"flex flex-row flex-nowrap items-center gap-1"},[_c('label',[_vm._v("\n              "+_vm._s(_vm.$t('CHATLYN_MESSAGE_TEMPLATE.SEND_MODAL.RECIPIENTS'))+"\n            ")]),_vm._v(" "),(_vm.contactList && _vm.contactList.name)?_c('fluent-icon',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:(
                _vm.$t(
                  'CHATLYN_CONTACT_LISTS.SEND_MESSAGE_MODAL.RECIPIENTS_TOOLTIP',
                  { list: _vm.contactList.name }
                )
              ),expression:"\n                $t(\n                  'CHATLYN_CONTACT_LISTS.SEND_MESSAGE_MODAL.RECIPIENTS_TOOLTIP',\n                  { list: contactList.name }\n                )\n              ",modifiers:{"top":true}}],attrs:{"icon":"info","size":"12"}}):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"text-4xl font-light dark:text-white"},[_vm._v("\n            "+_vm._s(_vm.contactsCount)+"\n          ")]),_vm._v(" "),(_vm.isSendMessageLimitShown)?_c('send-message-limit-warning',{staticClass:"mt-0.5",attrs:{"current-sending-limits":_vm.currentSendingLimits}}):_vm._e()],1):_vm._e(),_vm._v(" "),_c('div',{staticClass:"message-template-number-container"},[_c('div',{staticClass:"flex items-center gap-1"},[_c('label',[_vm._v("\n              "+_vm._s(_vm.$t(
                  'CHATLYN_MESSAGE_TEMPLATE.SEND_MODAL.SELECT_WHATSAPP_INBOX'
                ))+"\n            ")]),_vm._v(" "),_c('fluent-icon',{directives:[{name:"tooltip",rawName:"v-tooltip.top",value:(
                _vm.$t('CHATLYN_CONTACT_LISTS.SEND_MESSAGE_MODAL.NUMBER_TOOLTIP')
              ),expression:"\n                $t('CHATLYN_CONTACT_LISTS.SEND_MESSAGE_MODAL.NUMBER_TOOLTIP')\n              ",modifiers:{"top":true}}],attrs:{"icon":"info","size":"12"}})],1),_vm._v(" "),_c('input',{attrs:{"type":"text","disabled":""},domProps:{"value":`${_vm.selectedWhatsAppInbox.name} (${_vm.selectedWhatsAppInboxPhoneNumber})`}})]),_vm._v(" "),_c('div',[_c('label',[_vm._v("\n            "+_vm._s(_vm.$t('CHATLYN_MESSAGE_TEMPLATE.MESSAGE_PREVIEW'))+"\n          ")]),_vm._v(" "),_c('language-bar',{staticClass:"mb-2",attrs:{"has-color-mark":"","has-additional-text":false,"languages":_vm.languages,"translations":_vm.template.translations,"current-language":_vm.currentLanguage},on:{"change":(language) => {
                if (!_vm.hasLinks) {
                  _vm.selectCurrentLanguage(language);
                }
              }}}),_vm._v(" "),(_vm.messageTemplateTranslation)?_c('message-preview',{attrs:{"has-label":false,"current-translation":_vm.messageTemplateTranslation,"modal-mode":false,"custom-header-attachment":_vm.customFile,"empty-text":_vm.$t('CHATLYN_MESSAGE_TEMPLATE.SELECT_TEMPLATE_TO_PREVIEW')}}):_vm._e()],1)])])]),_vm._v(" "),_c('div',{staticClass:"flex justify-end gap-2 px-8 pb-8 pt-0"},[_c('woot-button',{attrs:{"color-scheme":"success","disabled":_vm.isSubmitButtonDisabled || hasErrors,"icon":_vm.showSchedule ? 'send-clock' : 'send'},on:{"click":function($event){$event.preventDefault();return _vm.sendMessage.apply(null, arguments)}}},[_vm._v("\n      "+_vm._s(_vm.$t(
          _vm.showSchedule
            ? 'CHATLYN_GENERAL.BUTTON.SEND_SCHEDULED'
            : 'CHATLYN_GENERAL.BUTTON.SEND'
        ))+"\n    ")])],1),_vm._v(" "),(_vm.isMessageTemplateLoading)?_c('div',{staticClass:"absolute left-0 top-0 z-10 flex h-full w-full items-center justify-center",staticStyle:{"background":"rgba(0, 0, 0, 0.05)"}},[_c('span',{staticClass:"spinner h-8 w-8"})]):_vm._e()]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }