<template>
  <div
    class="fixed z-[9999] cursor-pointer outline-none"
    :style="style"
    tabindex="0"
    @blur="$emit('close')"
  >
    <slot />
  </div>
</template>
<script>
// Services //
import { getFixedVerticalPosition } from '../../helper/contextMenuPositionHelper';

export default {
  props: {
    x: {
      type: Number,
      default: 0,
    },
    y: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      currentX: 0,
      currentY: 0,
      show: false,
    };
  },
  computed: {
    style() {
      return {
        top: this.currentY + 'px',
        left: this.currentX + 'px',
      };
    },
  },
  watch: {
    x: {
      handler(newValue) {
        this.currentX = newValue;
      },
      immediate: true,
    },

    y: {
      handler(newValue) {
        this.currentY = newValue;
      },
      immediate: true,
    },

    currentY: {
      handler(newValue, prevValue) {
        if (newValue === prevValue) {
          return;
        }

        this.$nextTick(() => {
          const menuElement = this.$el;

          if (!menuElement) {
            return;
          }

          const verticalPosition = getFixedVerticalPosition(menuElement);

          if (!verticalPosition.newY) {
            return;
          }

          this.currentY = verticalPosition.newY;
        });
      },
      immediate: true,
    },
  },
  mounted() {
    this.$nextTick(() => this.$el.focus());
  },
};
</script>
