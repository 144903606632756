<template>
  <div>
    <div v-if="isConsent" class="message-template-form-switch-container">
      <woot-switch
        :id="`message-template-form-show-BUTTONS-${currentTranslation.language}-switch`"
        v-model="showButtons"
        class="message-template-form-switch"
        disabled-color="var(--s-100)"
        :label="$t('CHATLYN_MESSAGE_TEMPLATE.CREATE_MODAL.CONSENT.BUTTON')"
        :optional-text="isConsent ? '' : $t('CHATLYN_GENERAL.LABEL.OPTIONAL')"
        disabled
      />

      <slide-animation :is-shown="showButtons">
        <template v-if="showButtons">
          <transition name="fade">
            <div v-if="consentButtons" class="message-templates-buttons-list">
              <div
                class="callout warning my-2 flex flex-row flex-nowrap items-center gap-3 text-xs"
              >
                <span>
                  <fluent-icon icon="checkbox-person" type="solid" :size="16" />
                </span>

                <span>
                  {{
                    $t(
                      'CHATLYN_MESSAGE_TEMPLATE.CREATE_MODAL.CONSENT.HELP_TEXT'
                    )
                  }}
                </span>
              </div>

              <draggable
                v-if="consentButtons.length"
                v-model="consentButtons"
                handle=".draggable-handle"
              >
                <template
                  v-for="(
                    buttonComponent, buttonComponentIndex
                  ) in consentButtons"
                >
                  <formulate-input :key="buttonComponent.id" type="group">
                    <template #default>
                      <language-form-button-editor
                        :id="buttonComponent.id"
                        :button-component="buttonComponent"
                        :button-component-index="buttonComponentIndex"
                        :selected-button-tab-index="1"
                        :links-component="linksComponent"
                        :is-consent="isConsent"
                        :preferred-language="currentTranslation.language"
                        :buttons-component-texts="buttonsComponentTexts"
                        :is-translatable="isTranslatable"
                        @remove-button="removeButtonsById"
                        @change-links-component="changeLinksComponent"
                        @change-button-component="changeButtonComponent"
                      />
                    </template>
                  </formulate-input>
                </template>
              </draggable>
            </div>
          </transition>
        </template>
      </slide-animation>
    </div>

    <div v-else>
      <div class="flex flex-col gap-3">
        <div class="message-template-form-switch-container">
          <woot-switch
            :id="`message-template-form-show-BUTTONS-${currentTranslation.language}-quick-reply-switch`"
            v-model="showQuickReplyButtons"
            class="message-template-form-switch"
            disabled-color="var(--s-100)"
            :label="
              $t('CHATLYN_MESSAGE_TEMPLATE.CREATE_MODAL.QUICK_REPLY_BUTTONS')
            "
            :optional-text="
              isConsent ? '' : $t('CHATLYN_GENERAL.LABEL.OPTIONAL')
            "
            :disabled="isConsent"
          />

          <slide-animation :is-shown="showQuickReplyButtons">
            <template v-if="showQuickReplyButtons">
              <transition name="fade">
                <div
                  v-if="quickReplyButtons"
                  class="message-templates-buttons-list"
                >
                  <draggable
                    v-if="quickReplyButtons.length"
                    v-model="quickReplyButtons"
                    handle=".draggable-handle"
                  >
                    <template
                      v-for="(
                        buttonComponent, buttonComponentIndex
                      ) in quickReplyButtons"
                    >
                      <formulate-input :key="buttonComponent.id" type="group">
                        <template #default>
                          <language-form-button-editor
                            :id="buttonComponent.id"
                            :button-component="buttonComponent"
                            :button-component-index="buttonComponentIndex"
                            :selected-button-tab-index="1"
                            :links-component="linksComponent"
                            :is-consent="isConsent"
                            :preferred-language="currentTranslation.language"
                            :buttons-component-texts="buttonsComponentTexts"
                            :is-translatable="isTranslatable"
                            @remove-button="removeButtonsById"
                            @change-links-component="changeLinksComponent"
                            @change-button-component="changeButtonComponent"
                          />
                        </template>
                      </formulate-input>
                    </template>
                  </draggable>

                  <div
                    class="mt-2 flex flex-col flex-nowrap items-center gap-2 rounded-md"
                  >
                    <div
                      v-if="
                        !Array.isArray(buttonsComponent.buttons) ||
                        !buttonsComponent.buttons.length
                      "
                      class="p-3 text-center text-base text-gray-700 dark:text-gray-400"
                    >
                      {{
                        $t('CHATLYN_MESSAGE_TEMPLATE.CREATE_MODAL.NO_BUTTONS')
                      }}
                    </div>

                    <woot-button
                      color-scheme="secondary"
                      variant="smooth"
                      size="small"
                      icon="arrow-reply-all"
                      type="button"
                      :disabled="isAddButtonDisabled"
                      @click="addNewButton"
                    >
                      {{ $t('CHATLYN_MESSAGE_TEMPLATE.BUTTON.NEW_BUTTON') }}
                    </woot-button>
                  </div>
                </div>
              </transition>
            </template>
          </slide-animation>
        </div>

        <div class="message-template-form-switch-container">
          <woot-switch
            :id="`message-template-form-show-BUTTONS-${currentTranslation.language}-call-to-actions-switch`"
            v-model="showCallToActionsButtons"
            class="message-template-form-switch"
            disabled-color="var(--s-100)"
            :label="
              $t('CHATLYN_MESSAGE_TEMPLATE.CREATE_MODAL.CALL_TO_ACTION_BUTTONS')
            "
            :optional-text="
              isConsent ? '' : $t('CHATLYN_GENERAL.LABEL.OPTIONAL')
            "
            :disabled="isConsent"
          />

          <slide-animation :is-shown="showCallToActionsButtons">
            <template v-if="showCallToActionsButtons">
              <transition name="fade">
                <div
                  v-if="callToActionButtons"
                  class="message-templates-buttons-list"
                >
                  <draggable
                    v-if="callToActionButtons.length"
                    v-model="callToActionButtons"
                    handle=".draggable-handle"
                  >
                    <template
                      v-for="(
                        buttonComponent, buttonComponentIndex
                      ) in callToActionButtons"
                    >
                      <formulate-input :key="buttonComponent.id" type="group">
                        <template #default>
                          <language-form-button-editor
                            :id="buttonComponent.id"
                            :button-component="buttonComponent"
                            :button-component-index="buttonComponentIndex"
                            :selected-button-tab-index="0"
                            :links-component="linksComponent"
                            :is-consent="isConsent"
                            :preferred-language="currentTranslation.language"
                            :buttons-component-texts="buttonsComponentTexts"
                            :is-translatable="isTranslatable"
                            @remove-button="removeButtonsById"
                            @change-links-component="changeLinksComponent"
                            @change-button-component="changeButtonComponent"
                          />
                        </template>
                      </formulate-input>
                    </template>
                  </draggable>

                  <div
                    class="mt-2 flex flex-col flex-nowrap items-center gap-2 rounded-md px-4 py-2 text-center"
                    :class="{
                      'bg-woot-50 dark:bg-woot-800':
                        !callToActionButtons.length,
                    }"
                  >
                    <div
                      v-if="!callToActionButtons.length"
                      class="text-woot-500 text-sm"
                    >
                      {{
                        $t(
                          'CHATLYN_MESSAGE_TEMPLATE.CREATE_MODAL.NO_CALL_TO_ACTION_BUTTONS'
                        )
                      }}
                    </div>

                    <div class="flex flex-row flex-nowrap gap-2">
                      <woot-button
                        :disabled="isAddUrlButtonDisabled"
                        :color-scheme="
                          callToActionButtons.length ? 'secondary' : 'primary'
                        "
                        :variant="
                          callToActionButtons.length ? 'smooth' : undefined
                        "
                        size="small"
                        icon="open"
                        type="button"
                        @click="addSpecificButton('URL')"
                      >
                        {{
                          $t('CHATLYN_MESSAGE_TEMPLATE.BUTTON.NEW_URL_BUTTON')
                        }}
                      </woot-button>

                      <woot-button
                        :disabled="isAddPhoneNumberButtonDisabled"
                        :color-scheme="
                          callToActionButtons.length ? 'secondary' : 'primary'
                        "
                        :variant="
                          callToActionButtons.length ? 'smooth' : undefined
                        "
                        size="small"
                        icon="call"
                        type="button"
                        @click="addSpecificButton('PHONE_NUMBER')"
                      >
                        {{
                          $t('CHATLYN_MESSAGE_TEMPLATE.BUTTON.NEW_PHONE_BUTTON')
                        }}
                      </woot-button>
                    </div>
                  </div>
                </div>
              </transition>
            </template>
          </slide-animation>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import getUuid from 'widget/helpers/uuid';

import draggable from 'vuedraggable';
import WootSwitch from 'shared/components/ui/Switch';
import LanguageFormButtonEditor from './LanguageFormButtonEditor.vue';
import SlideAnimation from '../../../../../../../components/animations/SlideAnimation.vue';

const MAX_BUTTONS_LENGTH = 10;
const MAX_URL_BUTTON_LENGTH = 2;
const MAX_PHONE_NUMBER_BUTTON_LENGTH = 1;

export default {
  name: 'LanguageFormButtonsEditor',

  components: {
    WootSwitch,
    draggable,
    LanguageFormButtonEditor,
    SlideAnimation,
  },

  props: {
    currentTranslation: {
      type: Object,
      required: true,
    },

    category: {
      type: String,
      required: true,
    },

    linksComponent: {
      type: Object,
      default: () => ({
        body: [],
        buttons: [],
      }),
    },

    isTranslatable: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isCallToActionButtonsActive: false,
      isQuickReplyButtonsActive: false,
    };
  },

  computed: {
    buttonsComponent: {
      get() {
        return this.currentTranslation.components.find(
          (component) => component.type === 'BUTTONS'
        );
      },

      set(newValue) {
        const oldValue = structuredClone(newValue);

        oldValue.buttons = oldValue.buttons.map((button) => {
          if (
            !Array.isArray(button.example) ||
            !button.example.length ||
            !button.url
          ) {
            return button;
          }

          button.url = button.url.replaceAll(/{{([1-9]{1}|10)}}/gm, `{{1}}`);

          return button;
        });

        this.changeCurrentTranslation({
          ...this.currentTranslation,
          components: this.currentTranslation.components.map((component) =>
            component.type === 'BUTTONS' ? oldValue : component
          ),
        });

        this.$nextTick(() => {
          const newLinksComponent = structuredClone(this.linksComponent);
          newLinksComponent.buttons = [];

          this.buttonsComponent.buttons.forEach((button, buttonIndex) => {
            if (!Array.isArray(button.example) || !button.example.length) {
              return;
            }

            newLinksComponent.buttons.push({
              example: button.example[0],
              number: buttonIndex,
              value: '',
            });
          });

          this.changeLinksComponent(newLinksComponent);
        });
      },
    },

    showButtons: {
      get() {
        return this.currentTranslation.components.some(
          (component) => component.type === 'BUTTONS'
        );
      },

      set(newValue) {
        let newComponents = structuredClone(this.currentTranslation.components);

        if (this.showCallToActionsButtons || this.showQuickReplyButtons) {
          return;
        }

        if (!newValue) {
          this.isCallToActionButtonsActive = false;
          this.isQuickReplyButtonsActive = false;

          newComponents = newComponents.filter(
            (component) => component.type !== 'BUTTONS'
          );

          const newLinksComponent = structuredClone(this.linksComponent);
          newLinksComponent.buttons = [];

          this.changeLinksComponent(newLinksComponent);
        }

        if (
          newValue &&
          !newComponents.some((component) => component.type === 'BUTTONS')
        ) {
          newComponents.push({
            type: 'BUTTONS',
            buttons: this.isConsent ? this.getDefaultButtonsValue(1) : [],
          });
        }

        this.changeCurrentTranslation({
          ...this.currentTranslation,
          components: newComponents,
        });
      },
    },

    showQuickReplyButtons: {
      get() {
        return this.isQuickReplyButtonsActive || this.hasQuickReplyButtons;
      },

      set(newValue) {
        if (!newValue) {
          // TODO WARNING
          this.removeButtonsById(
            this.quickReplyButtons?.map((button) => button.id)
          );

          if (!this.showCallToActionsButtons) {
            this.showButtons = false;
          }
        } else {
          if (!this.showCallToActionsButtons) {
            this.showButtons = true;
          }

          const newButtons = this.getDefaultButtonsValue(1);

          const availableButtonsLength =
            MAX_BUTTONS_LENGTH -
            (this.buttonsComponent?.buttons?.length || 0) -
            newButtons.length;

          const countToDelete =
            availableButtonsLength >= 0 ? 0 : -availableButtonsLength;

          newButtons.splice(newButtons.length - countToDelete, countToDelete);

          this.$nextTick(() => {
            this.changeButtons([...newButtons, ...this.callToActionButtons]);
          });
        }

        this.isQuickReplyButtonsActive = newValue;
      },
    },

    showCallToActionsButtons: {
      get() {
        return this.isCallToActionButtonsActive || this.hasCallToActionButtons;
      },

      set(newValue) {
        if (!newValue) {
          // TODO WARNING
          this.removeButtonsById(
            this.callToActionButtons?.map((button) => button.id)
          );

          if (!this.showQuickReplyButtons) {
            this.showButtons = false;
          }
        } else if (!this.showQuickReplyButtons) {
          this.showButtons = true;
        }

        this.isCallToActionButtonsActive = newValue;
      },
    },

    hasQuickReplyButtons() {
      return this.buttonsComponent?.buttons?.some(
        (button) => button.type === 'QUICK_REPLY'
      );
    },

    hasCallToActionButtons() {
      return this.buttonsComponent?.buttons?.some((button) =>
        ['PHONE_NUMBER', 'URL'].includes(button.type)
      );
    },

    quickReplyButtons: {
      get() {
        return (
          this.buttonsComponent?.buttons?.filter(
            (button) => button.type === 'QUICK_REPLY'
          ) || []
        );
      },

      set(newValue) {
        this.changeButtons([...newValue, ...this.callToActionButtons]);
      },
    },

    callToActionButtons: {
      get() {
        return (
          this.buttonsComponent?.buttons?.filter((button) =>
            ['PHONE_NUMBER', 'URL'].includes(button.type)
          ) || []
        );
      },

      set(newValue) {
        this.changeButtons([...this.quickReplyButtons, ...newValue]);
      },
    },

    consentButtons() {
      return (
        this.buttonsComponent?.buttons?.filter((button) => button.isConsent) ||
        []
      );
    },

    // --------------------------------------------------------------------------------------------------------------------------------------- //

    isAddButtonDisabled() {
      return this.buttonsComponent?.buttons?.length >= MAX_BUTTONS_LENGTH;
    },

    isAddUrlButtonDisabled() {
      const urlButtons =
        this.callToActionButtons.filter((button) => button.type === 'URL') ||
        [];

      return (
        this.isAddButtonDisabled || urlButtons.length >= MAX_URL_BUTTON_LENGTH
      );
    },

    isAddPhoneNumberButtonDisabled() {
      const phoneNumberButtons =
        this.callToActionButtons.filter(
          (button) => button.type === 'PHONE_NUMBER'
        ) || [];

      return (
        this.isAddButtonDisabled ||
        phoneNumberButtons.length >= MAX_PHONE_NUMBER_BUTTON_LENGTH
      );
    },

    defaultCallToActionButtons() {
      return [
        {
          type: 'PHONE_NUMBER',
          text: '',
          phone_number: '',
        },
        {
          type: 'URL',
          text: '',
          url: '',
        },
      ];
    },

    defaultQuickReplyButtons() {
      return [
        {
          type: 'QUICK_REPLY',
          text: '',
        },
      ];
    },

    defaultConsentButton() {
      return [
        {
          type: 'QUICK_REPLY',
          text: '',
          isConsent: true,
        },
      ];
    },

    isConsent() {
      return this.category === 'CONSENT';
    },

    buttonsComponentTexts() {
      const result = {};

      (this.buttonsComponent?.buttons || []).forEach((button) => {
        result[button.id] = button.text;
      });

      return result;
    },
  },

  watch: {
    isConsent: {
      handler(newValue) {
        let oldButtons = [];

        if (this.buttonsComponent) {
          oldButtons = structuredClone(this.buttonsComponent.buttons);
        }

        const isTheSameCategory = Boolean(
          oldButtons.length &&
            (newValue
              ? oldButtons.every((button) => button.isConsent)
              : !oldButtons.some((button) => button.isConsent))
        );

        if (isTheSameCategory) {
          return;
        }

        if (this.buttonsComponent) {
          this.changeButtons([]);
        }

        this.$nextTick(() => {
          this.showButtons = false;

          this.$nextTick(() => {
            this.showButtons = true;
          });
        });
      },
      immediate: true,
    },

    buttonsComponentTexts: {
      async handler(newValue) {
        if (!newValue) {
          return;
        }

        const form = this.$formulate.registry.get(
          `create-message-template-form-${this.currentTranslation.language}`
        );

        if (!form || typeof form.hasValidationErrors !== 'function') {
          return;
        }

        await form.hasValidationErrors();
      },
      deep: true,
    },
  },

  methods: {
    removeButtonsById(ids = []) {
      if (!Array.isArray(ids) || !ids.length) {
        return;
      }

      const newButtonComponent = structuredClone(this.buttonsComponent);

      newButtonComponent.buttons = newButtonComponent.buttons.filter(
        (button) => !ids.includes(button.id)
      );

      this.buttonsComponent = newButtonComponent;
    },

    getDefaultButtonsValue(index) {
      let defaultButtons;

      switch (index) {
        case 0:
          defaultButtons = structuredClone(this.defaultCallToActionButtons);
          break;

        case 1:
          defaultButtons = structuredClone(
            this.isConsent
              ? this.defaultConsentButton
              : this.defaultQuickReplyButtons
          );
          break;

        default:
          defaultButtons = [];
          break;
      }

      return defaultButtons.map((button) => ({
        ...button,
        id: getUuid(),
      }));
    },

    addNewButton() {
      if (this.isAddButtonDisabled || this.isConsent) {
        return;
      }

      this.changeButtons([
        ...this.quickReplyButtons,
        {
          id: getUuid(),
          type: 'QUICK_REPLY',
          text: '',
        },
        ...this.callToActionButtons,
      ]);
    },

    addSpecificButton(type) {
      if (
        this.isConsent ||
        (type === 'URL' && this.isAddUrlButtonDisabled) ||
        (type === 'PHONE_NUMBER' && this.isAddPhoneNumberButtonDisabled)
      ) {
        return;
      }

      const matchedButton = this.defaultCallToActionButtons.find(
        (button) => button.type === type
      );

      if (!matchedButton) {
        return;
      }

      this.changeButtons([
        ...this.quickReplyButtons,
        ...this.callToActionButtons,
        {
          ...matchedButton,
          id: getUuid(),
        },
      ]);
    },

    changeLinksComponent(newValue) {
      this.$emit('change-links-component', newValue);
    },

    changeCurrentTranslation(newValue) {
      this.$emit('change-current-translation', newValue);
    },

    changeButtons(newValue) {
      const newButtonComponent = structuredClone(this.buttonsComponent);

      newButtonComponent.buttons = newValue;

      this.buttonsComponent = newButtonComponent;
    },

    changeButtonComponent(newValue) {
      this.buttonsComponent = structuredClone({
        ...this.buttonsComponent,
        buttons: this.buttonsComponent.buttons.map((button) => {
          if (button.id === newValue.id) {
            return newValue;
          }

          return button;
        }),
      });
    },

    compareButtons(a, b) {
      const aButtons = structuredClone(a).map((button) => {
        delete button.id;
        return button;
      });

      const bButtons = structuredClone(b).map((button) => {
        delete button.id;
        return button;
      });

      return JSON.stringify(aButtons) === JSON.stringify(bButtons);
    },
  },
};
</script>

<style lang="scss">
.message-templates-buttons-list {
  .input-container {
    input {
      margin-bottom: 0 !important;
    }

    &.error {
      .message {
        margin-bottom: 0 !important;
      }
    }
  }

  select {
    margin-bottom: 0 !important;
  }
}
</style>
