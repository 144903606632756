var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex flex-nowrap"},[_c('woot-button',{staticClass:"aspect-square",class:{
      active: _vm.value,
      'rounded-e-none': _vm.isImmediateTranslateButtonVisible,
    },attrs:{"is-disabled":_vm.isDisabled,"icon":"translate","color-scheme":"secondary","variant":"smooth","type":"button","size":"small"},on:{"click":_vm.handleChange}}),_vm._v(" "),(_vm.isImmediateTranslateButtonVisible)?[_c('woot-button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
        content: _vm.$t('CHATLYN_TRANSLATOR.TRANSLATE_TO', {
          LANGUAGE: _vm.selectedDeeplLanguage.name,
        }),
        delay: { show: 500, hide: 100 },
      }),expression:"{\n        content: $t('CHATLYN_TRANSLATOR.TRANSLATE_TO', {\n          LANGUAGE: selectedDeeplLanguage.name,\n        }),\n        delay: { show: 500, hide: 100 },\n      }"}],staticClass:"appended-button !h-auto !w-auto max-w-[12.5rem] rounded-s-none border-0 px-3 py-2",attrs:{"is-disabled":_vm.isDisabled,"color-scheme":"secondary","variant":"smooth","type":"button","size":"small"},on:{"click":_vm.handleTranslateText}},[_c('div',{staticClass:"flex flex-row gap-1"},[_c('span',{staticClass:"overflow-hidden text-ellipsis whitespace-nowrap text-xs"},[_vm._v("\n          "+_vm._s(_vm.selectedDeeplLanguage.name)+"\n        ")]),_vm._v(" "),_c('fluent-icon',{attrs:{"icon":"arrow-enter-left","size":16}})],1)])]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }